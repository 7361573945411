import React from "react";
import "./Contacts.scss";
import email from "../../assets/icons/email.png";
import location from "../../assets/icons/location.png";
import phone from "../../assets/icons/phone.png";

function Contacts({ contactsClose, contactsClass }) {
  return (
    <div className={contactsClass}>
      <button className="contacts__close" onClick={contactsClose}>
        close
      </button>
      <p className="contacts__title">Contacts</p>
      <div className="contacts__items">
        <div className="contacts__item">
          <img alt="email icon" src={email} className="contacts__icons" />
          <p className="contacts__item__content">amy@aboutamykang.com</p>
        </div>
        <div className="contacts__item">
          <img alt="phone icon" src={phone} className="contacts__icons" />
          <p className="contacts__item__content">519.729.1223</p>
        </div>
        <div className="contacts__item">
          <img
            alt="location icon"
            src={location}
            className="contacts__icons contacts__icons--location"
          />
          <p className="contacts__item__content">Montreal, Canada</p>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
