import React from 'react';
import './Project.scss';
import {Link} from 'react-router-dom';

export default function Project({project}) {
    let linkURL = `/projects/${project.name}`;
    return (
        <div className="project">
            <img src={project.poster} className="project__prev" alt="project poster"/>
            <div className="btns">
                <Link to={linkURL} className="btns__btn btns__btn--border">Learn More</Link>

            </div>
            
        </div>
    )
}
