import React from 'react';
import './Footer.scss';

export default function Footer({contactsShow}) {
    return (
        <footer>
            <p className="footer-text">Want to work on Projects?</p>
            <button className="contact-btn" onClick={contactsShow}>Contact Me</button>
            

            
        </footer>
    )
}
