import React from 'react';
import './Header.scss';
import github from '../../assets/icons/github.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import girl from '../../assets/images/girl.jpg';



export default function Header({color}) {

    let headerColor = "header"
    if (color === "pink") {
        headerColor = "header header--pink"
    }

    return (
        <header className={headerColor}>
            <a href="/#home"><img alt="logo"src={girl} className="logo"/></a>
            <nav>
                <a href="/#about" className="nav__links">About</a>
                <a href="/#skills" className="nav__links">Skills</a>
                <a href="/#projects" className="nav__links">Projects</a>
                {/* <Link to="/" className="nav__links">Contact</Link> */}
            </nav>
            <nav>
                <a href="https://www.linkedin.com/in/amykang96/" rel="noreferrer" target="_blank"><img alt="linkedin" className="nav__social" src={linkedin}/></a>
                <a href="https://github.com/jh8kang" rel="noreferrer" target="_blank"><img alt="github"className="nav__social" src={github}/></a>
            </nav>
        </header>
    
    )
}
