import React, {useEffect} from 'react';
import './Bobo.scss';
import { useLocation } from "react-router-dom";
import {Header} from '../../../components/index';
import mobileDesign1 from '../../../assets/images/bobo/bobo-landing.png';
import mobileDesign2 from '../../../assets/images/bobo/bobo-login.png';
import mobileDesign3 from '../../../assets/images/bobo/bobo-shops.png';
import mobileDesign4 from '../../../assets/images/bobo/bobo-collect-list.png';
import mobileDesign5 from '../../../assets/images/bobo/bobo-qr.png';
import mobileDesign6 from '../../../assets/images/bobo/bobo-profile.png';

import mobileDesign8 from '../../../assets/images/bobo/bobo-store-login.png';
import mobileDesign9 from '../../../assets/images/bobo/bobo-store-home.png';
import mobileDesign10 from '../../../assets/images/bobo/bobo-storeqr.png';
import mobileDesign11 from '../../../assets/images/bobo/bobo-store-modal.png';
import mobileDesign12 from '../../../assets/images/bobo/bobo-store-profile.png';

import reactIcon from '../../../assets/icons/tools/react.png';
import nodeIcon from '../../../assets/icons/tools/node.png';
import sassIcon from '../../../assets/icons/tools/sass.png';
import expressIcon from '../../../assets/icons/tools/express.png';
import chartIcon from '../../../assets/icons/tools/chartjs.svg';

import Aos from 'aos';
import 'aos/dist/aos.css';

export default function Bobo() {
    const { pathname } = useLocation();
    useEffect(()=> {
        Aos.init({duration: 1000});
        window.scrollTo(0, 0);
    }, [pathname])

    return (
        <div className="project-page" id="bobo">
            <Header/>
            <div className="project-hero">
                <p className="project-hero__title">PROJECT - BOBO</p>
                <div className="github">
                    <a href="https://github.com/jh8kang/bobo-capstone" target="_blanc" className="github__btn github__btn--bobo">GitHub</a>
                </div>
            </div>
            <section className="project-contents">
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESCRIPTION</p>
                        <p>BOBO is an application where users can collect bubble tea points from various different stores but in one app. The idea came from all the point cards stuffed inside my wallet. I thought it would be handy to have an application that will keep track of all the store points. </p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">ROLES</p>
                        <p>Design/Fonrt-end/Back-end: Amy Kang</p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">APP FLOW</p>
                        <p>Two different flows depending on if you’re a collector or a store owner. </p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESIGN</p>
                        <p>The design was focused more on making it easy to navigate through the components making it fast and easy to collect points while in store. </p>
                    </div>

                    <p className="project-contents__subtitle">Collector Side:</p>
                    <div className="designs-mobile designs-mobile--bobo">
                        <div  className="designs-mobile__row">
                            <img alt="screenshot" data-aos="fade-right" src={mobileDesign1} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-right" src={mobileDesign3} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-left" src={mobileDesign2} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-left" src={mobileDesign4} className="designs-mobile__pic"/>
                        </div>
                        <div className="designs-mobile__row designs-mobile__row--bottom">
                            <img alt="screenshot"data-aos="fade-right" src={mobileDesign5} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-right" src={mobileDesign6} className="designs-mobile__pic"/>
                        </div>
                    </div>
                    <p className="project-contents__subtitle">Store Owner Side:</p>
                    <div className="designs-mobile designs-mobile--bobo">
                        <div className="designs-mobile__row">
                            <img alt="screenshot"data-aos="fade-right" src={mobileDesign8} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-right"src={mobileDesign9} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-left"src={mobileDesign10} className="designs-mobile__pic"/>
                            <img alt="screenshot"data-aos="fade-left"src={mobileDesign11} className="designs-mobile__pic"/>
                        </div>
                        <div className="designs-mobile__row designs-mobile__row--bottom">
                            <img alt="screenshot"data-aos="fade-right"src={mobileDesign12} className="designs-mobile__pic"/>
                            <ul data-aos="fade-left" className="notes-on-design">
                                <li>The Point Manager Page contains a camera where the qr code can be scanned which will then bring up the client modal where points can be added. </li>
                                <li>Home Page contains a graph that shows the amount of points were collected on a particular day of the week.</li>
                            </ul>
                        </div>
                    </div>

  
                </section>
                <section className="tools-sec">
                    <p>Tools used in the process:</p>
                    <div className="tools-sec__container">
                        <img alt="react"src={reactIcon} className="tools-sec__item"/>
                        <img alt="node"src={nodeIcon} className="tools-sec__item"/>
                        <img alt="sass"src={sassIcon} className="tools-sec__item"/>
                        <img alt="express"src={expressIcon} className="tools-sec__item"/>
                        <img alt="chartjs"src={chartIcon} className="tools-sec__item"/>
                    </div>
                </section>
            
        </div>
    )
}

