import React, {useEffect} from 'react';
import './BrainFlix.scss';
import {Header} from '../../../components/index';
import { useLocation } from "react-router-dom";

import reactIcon from '../../../assets/icons/tools/react.png';
import nodeIcon from '../../../assets/icons/tools/node.png';
import sassIcon from '../../../assets/icons/tools/sass.png';
import expressIcon from '../../../assets/icons/tools/express.png';
import demoVideo from '../../../assets/images/brainflix/brainflix.mp4';


export default function BrainFlix() {
    const { pathname } = useLocation();
    useEffect(()=> {
        window.scrollTo(0, 0);
    }, [pathname])
    return (
        <div className="brainflix">
            <Header/>
            <div className="project-hero">
                <p className="project-hero__title">PROJECT - BRAINFLIX</p>
                <div className="github">
                    <a href="https://github.com/jh8kang/amy-kang-brainflix" target="_blanc" className="github__btn">GitHub</a>
                </div>
            </div>
            <section className="project-contents">
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESCRIPTION</p>
                        <p>BrainFlix is an application that I built through BrainStation. It was the first application which had a back-end and a front-end. This project was particularly interesting since it was dynamic.</p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">ROLES</p>
                        <p>Design/Fonrt-end/Back-end: Amy Kang</p>
                    </div>

                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESIGN</p>
                        <p>Design was already given and the point was to make it exactly the same as the given design.</p>
                    </div>
                    
                    <div className="video">
                        <p className="video__subtitle">Demo of BrainFlix:</p>
                        <video src={demoVideo} width="70%" controls muted></video>
                    </div>

                    

  
                </section>
                <section className="tools-sec">
                    <p>Tools used in the process:</p>
                    <div className="tools-sec__container">
                        <img alt="react"src={reactIcon} className="tools-sec__item"/>
                        <img alt="node"src={nodeIcon} className="tools-sec__item"/>
                        <img alt="sass"src={sassIcon} className="tools-sec__item"/>
                        <img alt="express"src={expressIcon} className="tools-sec__item"/>
                    </div>
                </section>


        </div>
    )
}
