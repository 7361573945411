import React from 'react';
import './Projects.scss';
import Project from './Project/Project';
import poster1 from '../../assets/images/poster(bobo).png';
import poster2 from '../../assets/images/poster(brainflix).png';
import poster3 from '../../assets/images/poster(yourturn).png';
import {v4 as uuid} from 'uuid';


let projectsList = [
    {
        name: "bobo",
        poster: poster1,
    },
    {
        name: "brainflix",
        poster: poster2,
    },
    {
        name: "yourturn",
        poster: poster3,
    },


]

export default function Projects() {

    return (
        <section className="projects" id="projects">
            <h2 className="projects__title" data-aos="fade-right" data-aos-duration="1000">PROJECTS</h2>
            <div className="projects__list" data-aos="fade-left" data-aos-duration="1000">
                {projectsList.map(project => <Project project={project} key={uuid()}/>)}

            </div>
        </section>
    )
}
