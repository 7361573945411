import React, {useEffect} from 'react';
import './YourTurn.scss';
import { useLocation } from "react-router-dom";

import {Header} from '../../../components/index';
import design1 from '../../../assets/images/yourturn/befriend.png';
import design2 from '../../../assets/images/yourturn/breaktheice.png';
import design3 from '../../../assets/images/yourturn/yourturn-yellow.png';
import mobileDesign1 from '../../../assets/images/yourturn/yourturn-landing(mobile).png';
import mobileDesign2 from '../../../assets/images/yourturn/yourturn-games(mobile).png';
import mobileDesign3 from '../../../assets/images/yourturn/yourturn-create(mobile).png';
import deskDesign1 from '../../../assets/images/yourturn/yourturn-landing(desktop).png';
import deskDesign2 from '../../../assets/images/yourturn/yourturn-games(desktop).png';
import reactIcon from '../../../assets/icons/tools/react.png';
import nodeIcon from '../../../assets/icons/tools/node.png';
import reduxIcon from '../../../assets/icons/tools/redux.png';
import sassIcon from '../../../assets/icons/tools/sass.png';
import rubyIcon from '../../../assets/icons/tools/rubyrails.png';

import Aos from 'aos';
import 'aos/dist/aos.css';

export default function YourTurn() {
    const { pathname } = useLocation();

    useEffect(()=> { 
        Aos.init({duration: 1000,});
        window.scrollTo(0, 0);
    }, [pathname])


    return (
        <div className="project-page" id="yourturn">
            <Header/>
            <div className="project-hero">
                <p className="project-hero__title">PROJECT - YOURTURN</p>
                <div className="github">
                    <button className="github__btn">GitHub</button>
                    <p>code under construction - coming soon</p>
                </div>
            </div>
            <section className="project-contents">
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESCRIPTION</p>
                        <p>YourTurn is an app where friends can get together and play the most iconic games online and offline. The a user creates a room and shares the link to other players, they are able to join and therefore start chosing games to play from the list. </p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">ROLES</p>
                        <p>Design/Fonrt-end: Amy Kang</p>
                        <p>Back-end: Nicole Bae</p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">APP FLOW</p>
                        <p>Landing page -> Create room -> Lobby (Others join via Join room page) -> Games -> Game Components.</p>
                    </div>
                    <div className="project-contents__para">
                        <p className="project-contents__subtitle">DESIGN</p>
                        <p>As for the design, it was focused on making the application look fun and exciting. Firstly, I created couple of drafts with different colors and layouts on Figma.</p>
                    </div>
                    <div className="designs">
                        <p className="designs__title">Screencaptures from Figma</p>
                        <img alt="screenshot" data-aos="fade-up" src={design1} className="designs__img"/>
                        <img alt="screenshot" data-aos="fade-up" src={design2} className="designs__img"/>
                        <img alt="screenshot" data-aos="fade-up" src={design3} className="designs__img"/>
                    </div>
                    <div className="project-contents__para">
                        <p>I then chose a design and color scheme that I thought would best fit the theme of being a game. </p>
                    </div>
                    <div className="designs-mobile">
                        <img alt="screenshot" data-aos="fade-right" src={mobileDesign1} className="designs-mobile__img"/>
                        <img alt="screenshot" data-aos="fade-right" src={mobileDesign2} className="designs-mobile__img"/>
                        <img alt="screenshot" data-aos="fade-right"  src={mobileDesign3} className="designs-mobile__img"/>
                    </div>
                    <div className="project-contents__para">
                        <p>Three main colors were used #5C6180 (purple), #EA698B (pink), and white. Then the design was implemented using react by mobile first then tablet/desktop.</p>
                    </div>
                    <div className="designs">
                        <img alt="screenshot" data-aos="fade-left"src={deskDesign1} className="designs__img"/>
                        <img alt="screenshot" data-aos="fade-right"src={deskDesign2} className="designs__img"/>
                    </div>
                </section>
                <section className="tools-sec">
                    <p>Tools used in the process:</p>
                    <div className="tools-sec__container">
                        <img alt="react"src={reactIcon} className="tools-sec__item"/>
                        <img alt="node"src={nodeIcon} className="tools-sec__item"/>
                        <img alt="redux"src={reduxIcon} className="tools-sec__item"/>
                        <img alt="sass"src={sassIcon} className="tools-sec__item"/>
                        <img alt="ruby"src={rubyIcon} className="tools-sec__item"/>
                    </div>
                </section>
                
            
        </div>
    )
}
