import React from 'react';
import './About.scss';
import profile from '../../assets/images/profile-pic2.jpg';
import resume from '../../assets/files/resume.pdf';

export default function About({contactsShow}) {

    return (
        <section className="about" id="about">
            <div data-aos="fade-up" data-aos-duration="1000"className="about__container">
                <div className="about__container__profile">
                    <img alt="profile"src={profile} className="profile"/>
                    <div className="profile__brown-box"></div>
                    <div className="profile__blue-box"></div>

                </div>
                <div className="about__container__content">
                    <h2 className="subtitle-about">ABOUT ME</h2>
                    <div className="paragraphs">
                        <div className="paragraphs__container">
                            <p className="paragraphs__paragraph">I’m Amy Kang. I am chemist turned into a coder. With my bachelor in science from University of Waterloo, I have been working as a lab technician ever since. However with some background in HTML, CSS, DrRacket, Python, and C from the elective I took in University, I have been experimenting with languages on the side. I absolutely love the challenges that comes with coding as I enjoy problem solving in creative and unique ways while trying to find the best solution. </p>
                            <p className="paragraphs__paragraph">I wanted to see if coding would remain as a hobby or if it was the career path I wanted to take. So in 2020, I enrolled in a part-time web development program. With the certificate came exposure to ruby on rails. The idea of being able to create an interactive application was really inspiring. I wanted to be skilled enough to create a whole application myself. That was when I decided to work part-time and enrol to the full-time web development program at BrainStation. </p>
                        </div>
                        <div>
                            <p className="paragraphs__paragraph">BrainStation has taught me the skills as well as the mindset needed to become a good web developer. Learning languages wasn’t that difficult for me as I enjoyed all of the process, but connecting the front-end and the back-end and polishing the app for deployment was always a question that I hadn’t solved. Through BrainStation I was able to connect everything I knew and learned together to create an app from start to finish. </p>
                            <div className="about__buttons">
                                <button className="about__buttons__btn" onClick={contactsShow}>Contact Me</button>
                                <a href={resume} target="_blank" rel="noreferrer"className="about__buttons__btn about__buttons__btn--pink">Resume</a>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

        </section>
    )
}
