import './App.css';
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {
  Home, 
  YourTurn,
  Bobo,
  BrainFlix,
} from "./components";
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(()=> {
      Aos.init({duration: 1000});
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/projects/yourturn" exact component={YourTurn}/>
          <Route path="/projects/bobo" exact component={Bobo}/>
          <Route path="/projects/brainflix" exact component={BrainFlix}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
