import React, {useEffect, useState} from 'react';
import './Home.scss';
import girl from '../../assets/images/girl.jpg';
import downArrow from '../../assets/icons/down-arrow.svg';

import { 
    Header,
    About,
    Education,
    Footer,
    Projects,
    Contacts
  } from "../../components";


export default function Home() {
    let [contactsClass, setContactsClass] = useState("contacts");


    useEffect(()=> {
    }, [contactsClass])

    let contactsShow = () => {
        setContactsClass("contacts contacts--visible");
    }
  
    let contactsClose = () => {
        setContactsClass("contacts contacts--close");
    }

    
    return (
        <div className="home" id="home">
            <Header color="beige"/>
            <Contacts contactsClose={contactsClose} contactsClass={contactsClass}/>
            <section className="hero">
                    <div className="hero__text">
                        <p className="hero__text__title">
                            <span className="hero__text__title__letter hero__text__title__letter--h">H</span>
                            <span className="hero__text__title__letter hero__text__title__letter--e">e</span>
                            <span className="hero__text__title__letter hero__text__title__letter--l">l</span>
                            <span className="hero__text__title__letter hero__text__title__letter--l2">l</span>
                            <span className="hero__text__title__letter hero__text__title__letter--o">o</span>
                        </p>
                        <p className="hero__text__sub-title">My name is Amy Kang,</p>
                        <p className="hero__text__sub-title">a web developer</p>

                    </div>
                    <div className="hero__img-container">
                        <img src={girl} className="hero__girl-img" alt="girl studying"/>
                        <div className="hero__girl-box"></div>
                        <div className="hero__girl-box hero__girl-box--back"></div>
                    </div>
                
                
                    <div className="hero__footer__scroll">
                        <p className="hero__footer__scroll-text">Scroll to learn more</p>
                        <img src={downArrow} alt="down arrow" className="hero__footer__scroll-arrow"/>
                    </div>
            
            </section>
            <About contactsShow={contactsShow}/>
            <Education/>
            <Projects/>
            <Footer contactsShow={contactsShow}/>
        </div>
    )
}
