import React from 'react';
import './Education.scss';
import school1 from '../../assets/icons/waterloouniverstiy.png';
import school2 from '../../assets/icons/lighthouselabs.jpg';
import school3 from '../../assets/icons/brainstationlogo.jpg';

export default function Education() {

    return (
        <section className="education" id="skills">
            <div className="education__container" data-aos="fade-right" data-aos-duration="1000">
                <h2 className="subtitle">EDUCATION/SKILLS</h2>
                <p className="text">My background in coding comes from various different institutions. Constantly learning independently to widen my spectrum of knowldge. </p>
                <div className="education__schools">
                    <div className="school-card">
                        <img alt="waterloo"src={school1} className="education__school-icon"/>
                        <p className="school-card__info">Gradudated Waterloo with a Chemistry degree. Took multiple CS courses as electives where I learned HTML, CSS, DrRacket, Python, C.</p>
                    </div>
                    <div className="school-card">
                        <img alt="lighthouse" src={school2} className="education__school-icon"/>
                        <p className="school-card__info">Obtained certificate for web development. Experienced working with Ruby on Rails.</p>
                    </div>
                    <div className="school-card">
                        <img alt="brainstation" src={school3} className="education__school-icon"/>
                        <p className="school-card__info">Obtained web development diploma. Learned React, Node.js, MySQL, Sass, Express, Firebase. </p>
                    </div>
                </div>
            </div>
            <div className="education__container-bars" data-aos="fade-left" data-aos-duration="1000">
                <div className="skill">
                    <p className="skill__name">Front-end</p>
                    <div className="skill__bar">
                        <div className="skill__bar__pink"></div>
                        <div className="skill__bar__base"></div>
                    </div>
                </div>
                <div className="skill">
                    <p className="skill__name">Back-end</p>
                    <div className="skill__bar">
                        <div className="skill__bar__pink skill__bar__pink--back-end"></div>
                        <div className="skill__bar__base"></div>
                    </div>
                </div>
                <div className="skill">
                    <p className="skill__name">React</p>
                    <div className="skill__bar">
                        <div className="skill__bar__pink skill__bar__pink--react"></div>
                        <div className="skill__bar__base"></div>
                    </div>
                </div>
                <div className="skill">
                    <p className="skill__name">Ruby</p>
                    <div className="skill__bar">
                        <div className="skill__bar__pink skill__bar__pink--ruby"></div>
                        <div className="skill__bar__base"></div>
                    </div>
                </div>
                <div className="skill">
                    <p className="skill__name">Design</p>
                    <div className="skill__bar">
                        <div className="skill__bar__pink skill__bar__pink--design"></div>
                        <div className="skill__bar__base"></div>
                    </div>
                </div>


            </div>
        </section>
    )
}
